import React, { useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TicketCard from "../../components/ticketCard/TicketCard";
import useAppointment from "../../hooks/useAppointment";
import useStore from "../../hooks/useStore";
import moment from "moment";
import { useParams } from "react-router";
import { channel } from "../../service/pusher";
import Navbar from "../../layout/navbar";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const TicketView = () => {
  const classes = useStyles();
  const { appointments, getAppointments } = useAppointment();
  const { store, getStore } = useStore();
  const { id } = useParams();
  const APPOINMENT_STORE_QUERY = `store[in]=${id}&startDate[gte]=${moment()
    .startOf("day")
    .format()}&startDate[lt]=${moment().endOf("day").format()}`;

  const handleStore = () => {
    getStore(id);
  };

  const handleAppointments = () => {
    getAppointments(APPOINMENT_STORE_QUERY);
  };

  useEffect(() => {
    handleStore();
    handleAppointments();

    channel.bind(`calendar-tickets-${id}`, () => {
      getAppointments(APPOINMENT_STORE_QUERY);
    });

    return () => {
      channel.unbind(`calendar-tickets-${id}`);
    };

    //eslint-disable-next-line
  }, [id]);

  return (
    <Container fullWidth className={classes.class}>
      <Grid item xs={12}>
        <Navbar store={store} />
      </Grid>
      <Grid item xs={12}>
        {appointments &&
          appointments.map((apt, i) => (
            <TicketCard data={apt} i={i} store={store} key={apt._id} />
          ))}
      </Grid>
    </Container>
  );
};

export default TicketView;
