const ObjExist = (obj, keys = [], defaultReturn = "", type = "default") => {
  let value = defaultReturn;

  if (!obj) return "";

  var currentObj = obj;

  for (let index = 0; index < keys.length; index++) {
    const current = keys[index];

    if (typeof currentObj[current] == "boolean" && type === "default")
      return currentObj[current] ? 1 : 0;

    if (!currentObj[current]) return defaultReturn;
    //eslint-disable-next-line
    value = (index, currentObj[current]);
    currentObj = currentObj[current];
  }

  return value;
};

export default ObjExist;
