import Pusher from "pusher-js";
import { DEVELOPMENT } from "../constants/environment";
import SITE_SETTINGS from "../constants/whiteLabel";
import {
  printByColor,
  RED_COLOR,
  GREEN_COLOR,
  BLUE_COLOR,
} from "../utils/logs";

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: "us2",
});

const pusherSubscription = pusher.subscribe(`${SITE_SETTINGS.client}_channels`);

class Channel {
  constructor() {
    this.callbacks = {};
  }

  bind(eventName, callback) {
    if (process.env.REACT_APP_NODE_ENV === DEVELOPMENT)
      printByColor({ message: `Bind: ${eventName}`, color: GREEN_COLOR });
    if (!this.callbacks[eventName]) {
      this.callbacks[eventName] = [];
    }
    pusherSubscription.bind(eventName, (data) => {
      if (process.env.REACT_APP_NODE_ENV === DEVELOPMENT)
        printByColor({
          message: `Callback: ${eventName}`,
          color: BLUE_COLOR,
        });
      callback(data);
    });
  }
  unbind(eventName) {
    if (process.env.REACT_APP_NODE_ENV === DEVELOPMENT)
      printByColor({ message: `Unbind: ${eventName}`, color: RED_COLOR });

    pusherSubscription.unbind(eventName);
  }
}

export const channel = new Channel();
