import React from "react";
import { useParams } from "react-router-dom";
import useAppointment from "../../hooks/useAppointment";
import moment from "moment";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidOutlinedIcon from "@material-ui/icons/PhoneAndroidOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import TitleOutlinedIcon from "@material-ui/icons/TitleOutlined";
import { useSnackbar } from "notistack";
import BeenhereOutlinedIcon from "@material-ui/icons/BeenhereOutlined";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import PersonIcon from "@material-ui/icons/Person";
import useAuth from "../../hooks/useAuth";
import { translateActions } from "../../utils/translate";
import VerifyNumber from "../../components/VerifyNumber";

import { makeStyles } from "@material-ui/core/styles";
import { isReceptionist, isRockstar } from "../../utils/AuthRoles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  confirm: {
    height: 60,
    width: "40ch",
  },
}));

const Appointment = () => {
  const classes = useStyles();
  const { user, isAuthenticated, loadUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isDisableButton, setDisableButton] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const { id } = useParams();

  const { appointment, getAppointment, visitLead } = useAppointment();

  React.useEffect(() => {
    if (!isAuthenticated) {
      loadUser();
    }
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (appointment) {
      setDisableButton(!appointment.status);
    }
    //eslint-disable-next-line
  }, [appointment]);

  React.useEffect(() => {
    getAppointment(id);
    //eslint-disable-next-line
  }, []);

  const handleVisit = async () => {
    await visitLead(id);

    setDisableButton(true);
    enqueueSnackbar("Visita Exitosa", {
      variant: "success",
    });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Container>
      {appointment.numVerify && (
        <VerifyNumber
          setDisableButton={setDisableButton}
          open={open}
          setOpen={setOpen}
          value={appointment.numVerify}
          message={"Por favor ingresa el código de confirmación"}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={10}>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Typography
              variant="h6"
              style={{
                textTransform: "capitalize",
                paddingTop: 40,
                paddingLeft: 10,
                paddingBottom: 5,
                fontSize: 25,
              }}
            >
              Datos del Cliente
            </Typography>
            <Paper elevation={0} style={{ padding: 30 }} variant="outlined">
              <Box display="flex" flexDirection="row" alignItems="center">
                <AccountCircleIcon style={{ marginRight: 5 }} />
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {appointment.lead && appointment.lead.name}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <EmailIcon style={{ marginRight: 5 }} />
                <Typography variant="h6">
                  {appointment.lead && appointment.lead.email}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <PhoneAndroidOutlinedIcon style={{ marginRight: 5 }} />
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {appointment.lead && appointment.lead.phone}
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Typography
              variant="h6"
              style={{
                textTransform: "capitalize",
                paddingTop: 10,
                paddingLeft: 10,
                paddingBottom: 5,
                fontSize: 25,
              }}
            >
              Datos de la Cita
            </Typography>
            <Paper elevation={0} style={{ padding: 30 }} variant="outlined">
              <Box display="flex" flexDirection="row" alignItems="center">
                <TitleOutlinedIcon style={{ marginRight: 5 }} />
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {appointment && appointment.title}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <EventOutlinedIcon style={{ marginRight: 5 }} />
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {appointment && appointment.description}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" alignItems="center">
                <ScheduleOutlinedIcon style={{ marginRight: 5 }} />
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {moment(appointment && appointment.startDate).format(
                    "DD MMMM YYYY  hh:mm a",
                  )}
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Typography
              variant="h6"
              style={{
                textTransform: "capitalize",
                paddingTop: 10,
                paddingLeft: 10,
                paddingBottom: 5,
                fontSize: 25,
              }}
            >
              Tipo de Cita
            </Typography>
            <Paper elevation={0} style={{ padding: 30 }} variant="outlined">
              <Box display="flex" flexDirection="row" alignItems="center">
                <TitleOutlinedIcon style={{ marginRight: 5 }} />
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {appointment &&
                    appointment.action &&
                    translateActions(appointment.action)}
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Typography
              variant="h6"
              style={{
                textTransform: "capitalize",
                paddingTop: 10,
                paddingLeft: 10,
                paddingBottom: 5,
                fontSize: 25,
              }}
            >
              Estatus de la Cita
            </Typography>
            <Paper elevation={0} style={{ padding: 30 }} variant="outlined">
              <Box display="flex" flexDirection="row" alignItems="center">
                <BeenhereOutlinedIcon style={{ marginRight: 5 }} />
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  Cita Confirmada
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Typography
              variant="h6"
              style={{
                textTransform: "capitalize",
                paddingTop: 10,
                paddingLeft: 10,
                paddingBottom: 5,
                fontSize: 25,
              }}
            >
              Fuente
            </Typography>
            <Paper elevation={0} style={{ padding: 30 }} variant="outlined">
              <Box display="flex" flexDirection="row" alignItems="center">
                <EmojiFlagsIcon style={{ marginRight: 5 }} />
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {appointment &&
                    appointment.lead &&
                    appointment.lead.source &&
                    appointment.lead.source.name}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Typography
              variant="h6"
              style={{
                textTransform: "capitalize",
                paddingTop: 10,
                paddingLeft: 10,
                paddingBottom: 5,
                fontSize: 25,
              }}
            >
              Agente
            </Typography>
            <Paper elevation={0} style={{ padding: 30 }} variant="outlined">
              <Box display="flex" flexDirection="row" alignItems="center">
                <PersonIcon style={{ marginRight: 5 }} />
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {appointment &&
                    appointment.lead &&
                    appointment.lead.agent &&
                    appointment.lead.agent.name}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          {user &&
            user.tier &&
            (isReceptionist(user.tier._id) || isRockstar(user.tier._id)) && (
              <Grid item xs={12} style={{ marginBottom: 10, marginTop: "4em" }}>
                <center>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isDisableButton}
                    className={classes.confirm}
                    onClick={() => {
                      // if(appointment &&  appointment.numVerify) setOpen(true)
                      // else
                      handleVisit();
                    }}
                  >
                    Confirmar Visita
                  </Button>
                </center>
              </Grid>
            )}
        </Grid>

        <Grid item xs></Grid>
      </Grid>
    </Container>
  );
};

export default Appointment;
