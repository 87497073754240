import React from "react";
import JWTLogin from "./JWTLogin";
import Menu from "../SelectStore";
import useAuth from "../../hooks/useAuth";

const LoginView = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) return <Menu />;

  return <JWTLogin />;
};

export default LoginView;
