import {
  GET_APPOINTMENTS,
  GET_APPOINTMENT,
  UPDATE_APPOINTMENT,
  SET_APPOINTMENTS,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_STORE,
} from "../types";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_APPOINTMENTS:
    case GET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
        loading: false,
        error: null,
      };
    case GET_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
        loading: false,
        error: null,
      };
    case GET_STORE:
      return {
        ...state,
        appointment: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
        loading: false,
        error: null,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CLEAR_STATE:
      return {
        appointment: {},
        appointments: [],
        loading: false,
        error: null,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
