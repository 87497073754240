export const translateSubstatus = (value) => {
  let translatedValue = "";

  if (!value) return "";

  translatedValue = value.replace("frontdesk", "Recepción");
  translatedValue = translatedValue.replace("frontdesk", "Recepción");
  translatedValue = translatedValue.replace("visit_followup", "Seguimiento");
  translatedValue = translatedValue.replace(
    "visit_rejected",
    "Visita Rechazada",
  );
  translatedValue = translatedValue.replace("separated", "Realizó Separación");
  translatedValue = translatedValue.replace(
    "rejected_application",
    "Solicitud Rechazada",
  );
  translatedValue = translatedValue.replace(
    "conditioned_application",
    "Solicitud Condicionada",
  );
  translatedValue = translatedValue.replace(
    "approved_application",
    "Solicitud Aprobada",
  );
  translatedValue = translatedValue.replace("application", "Llenó Solicitud");
  translatedValue = translatedValue.replace("sold", "Venta");
  translatedValue = translatedValue.replace("documentation", "Documentación");
  translatedValue = translatedValue.replace("rejected", "Rechazo");
  translatedValue = translatedValue.replace("wrongnumber", "Número Equivocado");
  translatedValue = translatedValue.replace("client_na", "Cliente N/A");
  translatedValue = translatedValue.replace("reschedule", "Reagendada");
  translatedValue = translatedValue.replace("visited", "Visita Exitosa");
  translatedValue = translatedValue.replace("visit_tracking", "Seguimiento");
  translatedValue = translatedValue.replace("confirmed", "Confirmada");
  translatedValue = translatedValue.replace("confirm", "Confirmada");
  translatedValue = translatedValue.replace("callagain", "Llamar de nuevo");
  translatedValue = translatedValue.replace("followup", "Seguimiento");
  translatedValue = translatedValue.replace("new", "Nuevo");
  return translatedValue;
};

export const translateActions = (value) => {
  let translatedValue = "";

  if (!value) return "";
  translatedValue = value.replace("information", "Información");
  translatedValue = translatedValue.replace("documentation", "Documentación");
  translatedValue = translatedValue.replace("driving test", "Prueba de Manejo");
  translatedValue = translatedValue.replace("drive", "Prueba de Manejo");

  return translatedValue;
};
