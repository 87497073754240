import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { translateActions } from "../../utils/translate";
import {
  Mail,
  MapPin,
  Calendar,
  Clock,
  Clipboard,
  Phone,
  Info,
} from "react-feather";
import AssistantIcon from "@material-ui/icons/Assistant";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    padding: "0px",
  },

  text: {
    fontSize: "1.7rem",
    textTransform: "uppercase",
    fontWeight: "bold",
  },

  textDate: {
    color: "white",
    fontSize: "31px",
    textTransform: "uppercase",
    fontWeight: "bold",
  },

  textSecondary: {
    textAlign: "center",
    fontSize: 21,
    fontWeight: "bold",
    textTransform: "uppercase",
  },

  ticketCard: {
    marginTop: 25,
    borderRadius: 20,
  },
  index: {
    background: "#6c5dbd",
  },
  indexTextColorWhite: {
    color: "white",
  },
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  listFlex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  cardGrid: {
    display: "grid",
    gridTemplateColumns: ".9fr .1fr 2fr 1.5fr 1fr",
    height: "14rem",
    overflow: "hidden",
  },

  dividerStyle: {
    height: "90%",
    margin: "auto auto",
  },
  qrStyle: {
    position: "relative",
  },
  imageStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: "auto",
  },
  checkStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: "auto",
    // color:'rgba(201,242,199,1)'
  },
  dateStyle: {
    position: "relative",
  },
  dateStyleL: {
    height: "120%",
    position: "absolute",
    left: 0,
    top: 0,
    right: "70%",
    bottom: 0,
    margin: "auto",
  },
  dateStyleR: {
    height: "120%",
    position: "absolute",
    left: "30%",
    top: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
  },
  startFlex: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "flex-start",
  },
  actionBox: {
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
});

const appointmentActionIcon = (action) => {
  switch (action) {
    case "drive":
      return (
        <Box style={{ borderRadius: "100%", padding: "18px" }}>
          <MapPin size={60} style={{ color: "#Ff0000" }} />
        </Box>
      );
    case "documentation":
      return (
        <Box style={{ borderRadius: "100%", padding: "18px" }}>
          <Clipboard size={60} style={{ color: "#Ff9800" }} />
        </Box>
      );
    case "mailing":
      return (
        <Box style={{ borderRadius: "100%", padding: "18px" }}>
          <Mail size={60} style={{ color: "#Ffc500" }} />
        </Box>
      );
    case "calling":
      return (
        <Box style={{ borderRadius: "100%", padding: "18px" }}>
          <Phone size={60} style={{ color: "#24ff00" }} />
        </Box>
      );
    default:
      return (
        <Box style={{ borderRadius: "100%", padding: "18px" }}>
          <Info size={60} style={{ color: "#008fff" }} />
        </Box>
      );
  }
};

const TicketCard = ({ data, i, store }) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.ticketCard}
      elevation={0}
      style={{ backgroundColor: "#ffffff" }}
    >
      <CardContent className={classes.cardGrid} style={{ padding: "0px" }}>
        <Box
          className={classes.dateStyle}
          style={{ width: "400px", padding: "0px" }}
        >
          <Box
            className={classes.dateStyleL}
            style={{ backgroundColor: store.make.color }}
          >
            <Box className={classes.centerFlex}>
              <List>
                <ListItem>
                  <Calendar className={classes.textDate} size={48} />
                </ListItem>
                <ListItem></ListItem>

                <ListItem>
                  <Clock className={classes.textDate} size={48} />
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box
            className={classes.dateStyleR}
            style={{ backgroundColor: store.make.color, opacity: "0.8" }}
          >
            <Box className={classes.startFlex}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography className={classes.textDate}>
                      {moment(data.startDate).format("DD MMM YYYY")}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem></ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography className={classes.textDate}>
                      {moment(data.startDate).format("hh:mm a")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Box>
        <Box className={classes.listFlex} style={{ width: "580px" }}>
          <List>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ListItem
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <IconButton style={{ margin: "0px 15px 0px 0px" }}>
                  {data && !data.status ? (
                    <AssistantIcon
                      className={classes.checkStyle}
                      style={{ color: "#12a612", fontSize: "33" }}
                      size={100}
                    />
                  ) : (
                    <AssistantIcon
                      className={classes.checkStyle}
                      style={{ color: "#746e6b", fontSize: "33" }}
                      size={100}
                    />
                  )}
                </IconButton>
                <ListItemText>
                  <Link
                    variant="h7"
                    color="inherit"
                    to={`/appointment/${data._id}`}
                    component={RouterLink}
                    className={classes.text}
                    style={{ display: "flex" }}
                  >
                    <Typography className={classes.text}>
                      {" "}
                      Cliente: {data && data.lead && data.lead.name}
                    </Typography>
                  </Link>
                </ListItemText>
              </ListItem>
            </Box>
            <ListItem>
              <ListItemText>
                <Typography
                  variant="h7"
                  component="h7"
                  className={classes.text}
                  style={{ fontSize: "22px", color: "#47484a" }}
                >
                  Asesor: {data && data.user && data.user.name}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>

        <Divider className={classes.dividerStyle} orientation="vertical" />
        <Box className={classes.centerFlex} style={{ width: 200 }}>
          <Box className={classes.actionBox}>
            {appointmentActionIcon(data.action)}
            <Typography className={classes.textSecondary}>
              {translateActions(data.action)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default TicketCard;
