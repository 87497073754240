import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Login from "./views/Login";
import CalendarById from "./views/CalendarById";
import Appointment from "./views/Appointment";
import AuthState from "./contexts/auth/AuthState";
import StoreState from "./contexts/Store/StoreState";
import AppointmentState from "./contexts/appointment/AppointmentState";

const App = () => {
  return (
    <SnackbarProvider>
      <Router>
        <AuthState>
          <AppointmentState>
            <StoreState>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/calendar/:id" component={CalendarById} />
                <Route exact path="/appointment/:id" component={Appointment} />
                <Route exact path="*" render={() => <Redirect to="/login" />} />
              </Switch>
            </StoreState>
          </AppointmentState>
        </AuthState>
      </Router>
    </SnackbarProvider>
  );
};

export default App;
